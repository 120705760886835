import React from 'react'
import StickyNavbar from '../Navbar/StickyNavbar';
import Footer from '../Footer/Footer'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import wmoreimg from './Images Homepage/wh-img-1.jpg'
import s1 from './Images Homepage/service-1.jpg'
import s2 from './Images Homepage/service-2.jpg'
import s3 from './Images Homepage/service-3.jpg'
import s4 from './Images Homepage/service-4.jpg'
import s5 from './Images Homepage/service-5.jpg'
import s6 from './Images Homepage/service-6.jpg'
import s7 from './Images Homepage/service-7.jpg'

const Servicepage = () => {
  return (
    <div>
      <section>
        <StickyNavbar></StickyNavbar>
      </section>

      <section>
        {/* Service Section */}
        <section className='servicesectionhome mt-16 bg-white pt-12 pb-16'>
          <div className="homservseccont w-full">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl ml-4 sm:ml-8 md:ml-12 text-gray-900 font-bold"><b>Our Services</b></h1>
            <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl ml-4 sm:ml-8 md:ml-12 text-slate-200">Take Business Services From Our Experienced Staff</h1>

            <div className='homeservslidecont mx-4 mt-6'>
            <Swiper className='rounded-lg'
              modules={[Navigation, Pagination, A11y]}
              spaceBetween={20}
              slidesPerView={1} // Default to showing 1 slide at a time
              centeredSlides={true} // Center the active slide
              navigation
              pagination={{ clickable: true }}
              breakpoints={{
                640: { slidesPerView: 1 }, // Show 1 slide for screens 640px and below
                768: { slidesPerView: 2 }, // Show 2 slide for screens between 641px and 768px
                1024: { slidesPerView: 3 }, // Show 3 slides for screens above 1024px
              }}
              initialSlide={1} // Set initial slide to index 1
              onSlideChange={(swiper) => {
                // Custom logic can be added here if needed
              }}
            >
                <SwiperSlide >
                  <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                    <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[560px] bg-white hover:bg-blue-700 hover:text-white'>
                      <img src={s1} alt="" className='w-full object-cover'/>
                      <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>BBPS</h1>
                      <p className='text-lg mt-4'>
                        Bharat Bill Payment System facilitates the installment of bills and enhances the security and speed of bill pay. The administration is accessible in different installment modes, on the web and through a system of specialists.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                    <div className='swiperslide-content rounded-3xl p-4 md:h-[600px] h-[600px] sm:p-6 bg-white hover:bg-blue-700 hover:text-white'>
                      <img src={s2} alt="" className='w-full object-cover'/>
                      <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>AEPS</h1>
                      <p className='text-lg mt-4'>
                        Aadhaar Enabled Payment System (AEPS) is a sort of installment framework that depends on the Unique Identification Number and permits Aadhaar card holders to consistently make money related exchanges through Aadhaar-based confirmation.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                    <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                      <img src={s3} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover'/>
                      <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>General Insurance</h1>
                      <p className='text-lg mt-4'>
                        General insurance or non-life insurance policies, including automobile and homeowners policies, provide payments depending on the loss from a particular financial event. General insurance is typically defined as any insurance that is not determined.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>

                {/* <SwiperSlide>
                  <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                    <div className='swiperslide-content rounded-3xl p-4 sm:p-6 bg-white hover:bg-blue-700 hover:text-white'>
                      <img src={s4} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover'/>
                      <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Domestic Money Transfer</h1>
                      <p className='text-lg mt-4'>
                        Domestic Money Transfer (DMT) benefit is an enormous market in India which got much more lift with current increment in advanced exchanges.The most vital part of a Money Transfer framework is a sheltered and secure application with perfect ongoing.
                      </p>
                    </div>
                  </div>
                </SwiperSlide> */}

                <SwiperSlide>
                  <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4 ">
                    <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                      <img src={s5} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover'/>
                      <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Mobile Recharge</h1>
                      <p className='text-lg mt-4'>
                        We provide recharge service in India for the mobile networks like Vi, Airtel, BSNL,  Jio
                      </p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                    <div className='swiperslide-content rounded-3xl p-4 md:h-[600px] h-[600px] sm:p-6 bg-white hover:bg-blue-700 hover:text-white'>
                      <img src={s6} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover'/>
                      <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Travel Booking</h1>
                      <p className='text-lg mt-4'>
                        Travel API is fundamentally set of web administrations to get to the movement bargains from various travel consolidators. GDS, outsider flight APIs, inn APIs – all are utilized by fly out offices to get to the movement bargains on the web.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                    <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                      <img src={s7} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover'/>
                      <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>DTH Recharge</h1>
                      <p className='text-lg mt-4'>
                        We provide online/Offline DTH recharge service provider. Our DTH service covers the service providers like Tata Sky, Dish TV, Sun Direct, Videocon D2H and Big TV.You can recharge it at the comfort of your home or office Gprs Based Recharge or simple.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
            </Swiper>
            </div>
          </div>
        </section>

        {/* More About Section */}
        <section className='whysunp bg-slate-50'>
          <div className='whysunpcont md:flex flex-col md:flex-row pt-12 pb-12 px-4 sm:px-8 md:px-12 lg:px-24'>
            <div className='lwhysunp w-full md:w-1/2'>
              <p className='text-red-600 text-lg sm:text-xl'>WHY CHOOSE US?</p><br />
              <h1 className='text-3xl sm:text-4xl font-black w-full sm:w-5/6'>
                We Offer Best Professional Services For Your Business
              </h1>
              <div className='leftwhysunpitemcont mt-8 sm:mt-12'>
                <div className='lwhysunpitem w-full sm:w-3/4 mb-4 py-4 gap-4 flex'>
                  <div className='itemicon'>
                    {/* Add icon here */}
                  </div>
                  <div className="itemtext pr-2">
                    <h1 className='text-xl sm:text-2xl'>B2B Platform</h1>
                    <p className='text-sm sm:text-base'>
                      Build your own Retailer Distributor Network with bug free mobile recharge software.
                    </p>              
                  </div>
                </div>

                <div className='lwhysunpitem w-full sm:w-3/4 mb-4 py-4 gap-4 flex'>
                  <div className='itemicon'>
                    {/* Add icon here */}
                  </div>
                  <div className="itemtext pr-2">
                    <h1 className='text-xl sm:text-2xl'>B2C Portals</h1>
                    <p className='text-sm sm:text-base'>
                      Online recharge portal connected directly with mobile, DTH, data card, Post Paid bill payment, electricity and utility payment platforms.
                    </p>
                  </div>
                </div>

                <div className='lwhysunpitem w-full sm:w-3/4 py-4 gap-4 flex'>
                  <div className='itemicon'>
                    {/* Add icon here */}
                  </div>
                  <div className="itemtext pr-2">
                    <h1 className='text-xl sm:text-2xl'>Android</h1>
                    <p className='text-sm sm:text-base'>
                      Multi recharge software includes Android application as standard feature of software. Publish your own brand name on Playstore.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='rwhysunpimg mt-4 md:mt-12 w-full md:w-1/2 border-4 border-zinc-600'>
                    <img src={wmoreimg} alt="" className='w-full h-[500px]'/>
              <div className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl bg-white text-center bottom-0 w-full py-4'>
                100% Trusted Company
              </div>
            </div>
          </div>
        </section>
      </section>

      <section>
        <Footer></Footer>
      </section>
    </div>
  )
}

export default Servicepage
